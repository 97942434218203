<template>
  <button class="cta-btn">
    {{ btn_name }}
    <slot></slot>
  </button>
</template>
<script>
export default {
  name: "v-button",
  props: {
    btn_name: {
      Type: [String],
      required: true,
      default: () => "",
    },
  },
};
</script>
<style lang="scss">
.cta-btn {
  min-width: 300px;
  // width: 100%;
  font-size: 18px;
  background-color: #040404;
  min-height: 70px;
  border: none;
  border-radius: 100px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 25px;
  padding-right: 13px;
  font-weight: 600;
  letter-spacing: 0.02em;
  font-family: "JetBrains Mono", sans-serif;
  cursor: pointer;
  &__image {
    padding: 15px 8px;
    border-radius: 50%;
    background-color: #fff;
  }
}
</style>

import { TimelineMax, Power3 } from "gsap";
import store from "../store";

const first_open = () => {
  let tl = new TimelineMax({});
  store.state.off_router = true;
  let animate_letters = document.querySelectorAll(
    ".case-loader__firstLoad-letter"
  );
  tl.set(".case-loader", {
    opacity: 1,
    zIndex: 2,
  });

  setTimeout(() => {
    tl.fromTo(
      animate_letters,
      1,
      {
        transform: `translateY(100%)`,
      },
      {
        stagger: 0.01,
        ease: Power3.easeInOut,
        transform: `translateY(0%)`,
      },
    );

    tl.to(animate_letters, 0.9, {
      delay: 0.8,
      ease: Power3.easeInOut,
      transform: `translateX(110%)`,
    });

    tl.to(".case-loader__animate-item", 1.4, {
      stagger: 0.1,
      ease: Power3.easeInOut,
      transform: `translateX(100%)`,
    });

    tl.set(".case-loader", {
      opacity: 0,
      zIndex: -2,
      onComplete: () => {
        store.state.off_router = false;
      },
    });
  }, 500);

  return tl;
};
const second_open = () => {
  let tl = new TimelineMax({});
  store.state.off_router = true;

  tl.set(".case-loader", {
    opacity: 1,
    zIndex: 2,
  });

  tl.fromTo(
    ".case-loader__animate-item",
    1.4,
    {
      stagger: 0.1,
      ease: Power3.easeInOut,
      transform: `translateX(-100%)`,
    },
    {
      stagger: 0.1,
      ease: Power3.easeInOut,
      transform: `translateX(0%)`,
    }
  );

  return tl;
};

const second_close = (tl) => {
  store.state.off_router = true;

  tl.to(".case-loader__animate-item", 1.4, {
    stagger: 0.1,
    ease: Power3.easeInOut,
    transform: `translateX(100%)`,
  });

  tl.set(".case-loader", {
    opacity: 0,
    zIndex: -2,
    onComplete: () => {
      store.state.off_router = false;
    },
  });
};

export default { first_open, second_open, second_close };

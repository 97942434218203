import { TimelineMax, Power3 } from "gsap";

const show_home_page = () => {
  const tl = new TimelineMax({}),
    home_portfolio_node = document.querySelector(".home__portfolio"),
    home_title_word_nodes = document.querySelectorAll(".home__info-title-word"),
    home_hire_btn_node = document.querySelector(".home__info-hire"),
    home_subtitle_node = document.querySelector(".home__info-subtitle"),
    home_suptitle_node = document.querySelector(".home__info-suptitle"),
    array_of_nodes = [
      home_suptitle_node,
      home_subtitle_node,
      home_hire_btn_node,
      home_portfolio_node,
    ];

  tl.to(home_title_word_nodes, 1, {
    stagger: 0.6,
    ease: Power3.easeInOut,
    transform: `translateY(0%)`,
  });

  tl.to(array_of_nodes, 0.9, {
    stagger: 0.05,
    ease: Power3.easeInOut,
    opacity: 1,
  });

  return tl;
};

export { show_home_page };

import { TimelineMax, Power3 } from "gsap";

const open_case = () => {
  let tl = new TimelineMax({});

  tl.set(".case-loader", {
    opacity: 1,
    zIndex: 2,
  });

  tl.fromTo(
    ".case-loader__animate-item",
    1.4,
    {
      stagger: 0.1,
      ease: Power3.easeInOut,
      transform: `translateX(-100%)`,
    },
    {
      stagger: 0.1,
      ease: Power3.easeInOut,
      transform: `translateX(0%)`,
      onComplete: () => {
        // this.$router.push(`/case/${data?.name}`);
      },
    }
  );


  tl.set(".case-loader__wrapper", {
    zIndex: 3,
  });

  tl.to(".case-loader__title", 0.8, {
    height: "auto",
    ease: Power3.easeInOut,
  });

  tl.to(
    ".case-loader__tasks",
    0.8,
    {
      delay: 0.5,
      height: "auto",
      ease: Power3.easeInOut,
    },
    "-=0.6"
  );

  tl.fromTo(".case-loader__delay", 0.2, { opacity: `0` }, { opacity: `1` })


  return tl;
};

const close_case = (tl) => {
  tl.to(".case-loader__title", 0.8, {
    delay: 1,
    height: "0",
    ease: Power3.easeInOut,
  });
  tl.to(
    ".case-loader__tasks",
    0.8,
    {
      height: "0",
      ease: Power3.easeInOut,
    },
    "-=0.6"
  );
  tl.to('.case-loader__delay', 0.6, { opacity: `0` }, '-=0.2')

  tl.to(".case-loader__animate-item", 1.4, {
    stagger: 0.1,
    ease: Power3.easeInOut,
    transform: `translateX(100%)`,
  });

  tl.set(".case-loader", {
    opacity: 0,
    zIndex: -2,
  });
};

export default { open_case, close_case };

<template>
  <section class="home" ref="home_page">
    <div class="home__container">
      <svg
        width="1252"
        height="1252"
        class="home__circle"
        viewBox="0 0 1252 1252"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_f_1271_1029)">
          <rect
            x="417"
            y="211"
            width="472"
            height="472"
            rx="236"
            fill="#5206CD"
          />
        </g>
        <g filter="url(#filter1_f_1271_1029)">
          <rect
            x="277"
            y="223"
            width="472"
            height="472"
            rx="236"
            fill="#CF7155"
          />
        </g>
        <g filter="url(#filter2_df_1271_1029)">
          <rect
            x="250"
            y="250"
            width="752"
            height="752"
            rx="376"
            fill="url(#paint0_linear_1271_1029)"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_1271_1029"
            x="267"
            y="61"
            width="772"
            height="772"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="75"
              result="effect1_foregroundBlur_1271_1029"
            />
          </filter>
          <filter
            id="filter1_f_1271_1029"
            x="127"
            y="73"
            width="772"
            height="772"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="75"
              result="effect1_foregroundBlur_1271_1029"
            />
          </filter>
          <filter
            id="filter2_df_1271_1029"
            x="0"
            y="0"
            width="1252"
            height="1252"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="125" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.952941 0 0 0 0 0.537255 0 0 0 0 0.188235 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_1271_1029"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_1271_1029"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="10"
              result="effect2_foregroundBlur_1271_1029"
            />
          </filter>
          <linearGradient
            id="paint0_linear_1271_1029"
            x1="343"
            y1="108.5"
            x2="783.5"
            y2="971.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#CF7155" />
            <stop offset="0.505659" stop-color="#F48A2F" />
            <stop offset="0.933452" stop-color="#F7C958" />
          </linearGradient>
        </defs>
      </svg>

      <!-- <svg
        width="1238"
        height="563"
        class="home__circle"
        viewBox="0 0 1238 563"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_df_806_308)">
          <rect
            x="236"
            y="250"
            width="752"
            height="752"
            rx="376"
            fill="url(#paint0_linear_806_308)"
          />
        </g>
        <defs>
          <filter
            id="filter0_df_806_308"
            x="-14"
            y="0"
            width="1252"
            height="1252"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="125" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.952941 0 0 0 0 0.537255 0 0 0 0 0.188235 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_806_308"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_806_308"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="9"
              result="effect2_foregroundBlur_806_308"
            />
          </filter>
          <linearGradient
            id="paint0_linear_806_308"
            x1="612"
            y1="139"
            x2="612"
            y2="1002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.017673" stop-color="#B27483" />
            <stop offset="0.198735" stop-color="#F7C958" />
            <stop offset="0.487913" stop-color="#F48A2F" />
            <stop offset="1" stop-color="#CF7155" />
          </linearGradient>
        </defs>
      </svg> -->

      <div class="home__info">
        <!-- <h3
          class="home__info-suptitle"
          :style="{ opacity: this.home_animate_styles.opacity }"
        >
          Web Design
          <span>
            & Development
            <v-button
              :btn_name="`Связаться`"
              class="home__info-suptitle-hire"
              @click.prevent="hireMe"
            />
          </span>
        </h3> -->
        <h3
          class="home__info-suptitle"
          :style="{ opacity: this.home_animate_styles.opacity }"
        >
          FullStack Web Development
          <span>
            & UI|UX Design
            <v-button
              :btn_name="`Связаться`"
              class="home__info-suptitle-hire"
              @click.prevent="hireMe"
            />
          </span>
        </h3>
        <h1 class="home__info-title">
          <div class="home__info-title-wrapper">
            <p
              class="home__info-title-word"
              :style="{
                transform: `translateY(${this.home_animate_styles.transform})`,
              }"
            >
              Привлекайте
            </p>
          </div>
          <div class="home__info-title-wrapper">
            <p
              class="home__info-title-word"
              :style="{
                transform: `translateY(${this.home_animate_styles.transform})`,
              }"
            >
              Удерживайте
            </p>
          </div>
          <div class="home__info-title-wrapper">
            <p
              class="home__info-title-word"
              :style="{
                transform: `translateY(${this.home_animate_styles.transform})`,
              }"
            >
              Масштабируйте
            </p>
          </div>
        </h1>
        <p
          class="home__info-subtitle"
          :style="{ opacity: this.home_animate_styles.opacity }"
        >
          1 - Проведем брифинг для выявления сильных сторон <br />
          2 - Упакуем преимущества в мощное рыночное предложение <br />
          3 - Определим, что важно для вашей аудитории, создадим логичную и
          удобную структуру сайта <br />
          4 - Проработаем текст для ответов на вопросы целевой аудитории <br />
          5 - На основе предыдущих шагов разработаем яркий и запоминающийся
          дизайн, выделяющий ваш проект среди конкурентов <br />
          6 - Разработаем сайт/приложение и разместим его на сервере
        </p>
        <v-button
          class="home__info-hire"
          @click.prevent="hireMe"
          :btn_name="`Связаться`"
          :style="{ opacity: this.home_animate_styles.opacity }"
        >
          <template #default>
            <img
              class="cta-btn__image"
              src="@/assets/media/svg/arrow.svg"
              alt=""
            />
          </template>
        </v-button>
      </div>
      <div
        class="home__portfolio"
        :style="{ opacity: this.home_animate_styles.opacity }"
      >
        <h2 class="home__portfolio-title">
          Последние проекты
          <router-link to="/portfolio" class="home__portfolio-link">
            Все проекты
          </router-link>
        </h2>
        <ul
          class="home__portfolio-list"
          ref="portfolioList"
          @wheel="scrollHorizontally"
        >
          <li
            class="home__portfolio-item"
            @click="toCase(work)"
            v-for="work in PORTFOLIO"
            :key="work.id"
            ref="portfolioItem"
            :style="{
              backgroundImage:
                'url(' +
                require('@/assets/media/img/' + work.background_image) +
                ')',
            }"
          >
            <div class="home__portfolio-info">
              <h3 class="home__portfolio-name">
                {{ work.name }}
              </h3>
              <p class="home__portfolio-description">
                {{ work.description.project_description }}
              </p>
              <p class="home__portfolio-label">
                <span v-for="(label, idx) in work.labels" :key="label">
                  {{ label }}
                  {{ idx !== work?.labels?.length - 1 ? "|" : "" }}
                </span>
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <router-link class="mobile__link-portfolio" to="/portfolio">
      Перейти в портфолио
    </router-link>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import case_animations from "../animations";
import { show_home_page } from "../animations/home_animations";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);
export default {
  name: "Home",
  data() {
    return {
      disable_horizontal_scroll: false,
    };
  },
  setup() {},
  components: {},
  methods: {
    ...mapActions(["GET_CASE_DATA", "SET_ROUTER_DISABLE"]),
    scrollHorizontally(evt) {
      if (this.$store.state.window_width > 1360) return;
      evt.preventDefault();
      const portfolioList = this.$refs.portfolioList;

      gsap.to(portfolioList, {
        scrollTo: {
          x: portfolioList.scrollLeft + evt.deltaY * 3,
          autoKill: false,
        },
        duration: 0.5,
      });
    },
    async toCase(data) {
      await this.GET_CASE_DATA(data?.name).then(() => {
        data.case_close_delay = data?.close_delay;
        case_animations.case_transitions.open_case().then((timeline) => {
          this.SET_ROUTER_DISABLE();
          this.$router.push(`/case/${data?.name}`);

          let close_case_interval = setInterval(() => {
            if (data?.case_close_delay <= 0) {
              clearInterval(close_case_interval);
            } else {
              data.case_close_delay = data?.case_close_delay - 1;
            }
          }, 1000);

          setTimeout(() => {
            case_animations.case_transitions.close_case(timeline);
            this.SET_ROUTER_DISABLE();
          }, data?.close_delay * 1000 || 1 * 1000);
        });
      });
    },
    hireMe() {
      window.open("https://t.me/AndrewDeveloper", "_blank");
    },
  },
  computed: {
    ...mapGetters(["PORTFOLIO", "FIRST_LOAD_DONE"]),
    home_animate_styles() {
      let styles = { opacity: 0, transform: `100%` };
      if (this.FIRST_LOAD_DONE && this.$store.state.home_page_load_done) {
        styles.opacity = 1;
        styles.transform = `0%`;
      }
      return styles;
    },
  },
  mounted() {
    const home_animate_interval = setInterval(() => {
      if (this.FIRST_LOAD_DONE && !this.$store.state.home_page_load_done) {
        show_home_page().then(() => {
          this.$store.state.home_page_load_done = true;
        });
        clearInterval(home_animate_interval);
      }
    }, 100);
  },
  watch: {},
  unmounted() {},
  beforeUnmount() {},
};
</script>

<style lang="scss" scoped>
.horizontal-scroll-wrapper {
  width: 100px;
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}
.horizontal-scroll-wrapper > div {
  width: 100px;
  height: 100px;
}
.horizontal-scroll-wrapper {
  transform: rotate(-90deg);
  transform-origin: right top;
}
.horizontal-scroll-wrapper > div {
  transform: rotate(90deg);
  transform-origin: right top;
}
.swiper1,
.swiper2 {
  min-height: 100px;
}
.home {
  width: 100%;
  display: flex;
  color: $white;
  height: 100%;
  padding-top: 3vh;
  &__container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__circle {
    position: fixed;
    bottom: -35%;
    max-width: 752px;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: -1;
  }
  &__info {
    padding: 0 40px 40px 40px;
    max-width: 748px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-start;
    &-suptitle {
      @include h3;
      font-weight: 100;
      font-family: "Cinzel", serif;
      text-transform: uppercase;
      span {
        display: flex;
        align-items: center;
      }
      &-hire {
        margin-left: 8px;
        color: $dark;
        background-color: $white;
        min-width: initial;
        padding: 8px 16px 8px 16px;
        min-height: initial;
        display: none;
      }
    }
    &-title {
      @include h1;
      font-size: 6vw;
      font-weight: 500;
      margin: 28px 0 22px 0;
      line-height: 110%;
      &-wrapper {
        overflow: hidden;
      }
      &-word {
      }
    }
    &-subtitle {
      margin-bottom: 40px;
      line-height: 140%;
      font-family: "JetBrains Mono", sans-serif;
    }
    &-hire {
      padding: 0 13px 0 25px;
      .cta-btn__image {
        padding: 15px 8px;
        border-radius: 50%;
        background-color: #fff;
      }
    }
  }
  &__portfolio {
    padding: 0 20px;
    padding-left: 0;
    padding-bottom: 70px;
    height: 100%;
    &-title {
      @include h3;
      margin-bottom: 45px;
      display: flex;
      justify-content: space-between;
      text-transform: uppercase;
      align-items: flex-end;
    }
    &-link {
      text-transform: none;
      font-size: 18px;
      letter-spacing: 0;
      font-weight: 400;
      white-space: nowrap;
    }
    &-list {
      height: 100%;
      overflow: auto;
      max-width: 552px;
      &::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
      }
    }
    &-item {
      cursor: pointer;
      width: 100%;
      min-height: 497px;
      border-radius: 25px;
      margin-right: 0;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      align-items: flex-end;
      padding: 10px;
      margin-bottom: 20px;
      &:last-child {
        margin-right: 20px;
      }
    }

    &-info {
      background-color: $dark;
      width: 100%;
      padding: 20px;
      border-radius: 25px;
    }
    &-name {
      @include h3;
      letter-spacing: 0;
    }
    &-description {
      margin: 14px 0;
    }
    &-label span {
      margin-right: 10px;
    }
  }
}
.mobile__link-portfolio {
  display: none;
}
// notebooks

// @media screen and (max-width: 1366px) and (min-width: 1024px) {
//   .home {
//     &__info {
//       max-width: 600px;
//       padding: 0 20px 20px 20px;
//       &-title {
//       }
//     }
//     &__portfolio {
//       max-width: 480px;
//       &-item {
//         max-width: auto;
//       }
//       &-link {
//         margin-left: 10px;
//       }
//     }
//   }
// }

@media (max-height: 787px) {
  .home {
    padding-top: 2vh;
    // .home__container

    &__container {
    }

    // .home__circle

    &__circle {
    }

    // .home__info

    &__info {
      &-suptitle {
        &-hire {
          // display: initial;
        }
      }
    }

    // .home__info-suptitle

    &__info-suptitle {
    }

    // .home__info-title

    &__info-title {
    }

    // .home__info-subtitle

    &__info-subtitle {
    }

    // .home__info-hire

    &__info-hire {
      // display: none;
    }

    // .home__portfolio

    &__portfolio {
    }

    // .home__portfolio-title

    &__portfolio-title {
    }

    // .home__portfolio-link

    &__portfolio-link {
    }

    // .home__portfolio-list

    &__portfolio-list {
    }

    // .home__portfolio-item

    &__portfolio-item {
    }

    // .home__portfolio-info

    &__portfolio-info {
    }

    // .home__portfolio-name

    &__portfolio-name {
    }

    // .home__portfolio-description

    &__portfolio-description {
    }

    // .home__portfolio-label

    &__portfolio-label {
    }
  }
  .cta-btn {
    // .cta-btn__image

    &__image {
    }
  }
  .mobile {
    // .mobile__link-portfolio

    &__link-portfolio {
    }
  }
}

@media (max-height: 746px) {
  .home {
    // .home__container

    &__container {
    }

    // .home__circle

    &__circle {
    }

    // .home__info

    &__info {
      &-suptitle {
        &-hire {
          // display: initial;
        }
      }
    }

    // .home__info-suptitle

    &__info-suptitle {
    }

    // .home__info-title

    &__info-title {
    }

    // .home__info-subtitle

    &__info-subtitle {
      margin-bottom: 2vh;
    }

    // .home__info-hire

    &__info-hire {
    }

    // .home__portfolio

    &__portfolio {
    }

    // .home__portfolio-title

    &__portfolio-title {
    }

    // .home__portfolio-link

    &__portfolio-link {
    }

    // .home__portfolio-list

    &__portfolio-list {
    }

    // .home__portfolio-item

    &__portfolio-item {
    }

    // .home__portfolio-info

    &__portfolio-info {
    }

    // .home__portfolio-name

    &__portfolio-name {
    }

    // .home__portfolio-description

    &__portfolio-description {
    }

    // .home__portfolio-label

    &__portfolio-label {
    }
  }
  .cta-btn {
    // .cta-btn__image

    &__image {
    }
  }
  .mobile {
    // .mobile__link-portfolio

    &__link-portfolio {
    }
  }
}

@media (max-width: 1366px) {
  .home {
    padding-top: 3vh;
    overflow: auto;
    // .home__container

    &__container {
      flex-direction: column;
      align-items: initial;
    }

    // .home__circle

    &__circle {
    }

    // .home__info

    &__info {
      padding: 0 24px 24px 24px;
    }

    // .home__info-suptitle

    &__info-suptitle {
    }

    // .home__info-title

    &__info-title {
      font-size: 7vw;
    }

    // .home__info-subtitle

    &__info-subtitle {
    }

    // .home__info-hire

    &__info-hire {
    }

    // .home__portfolio

    &__portfolio {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      padding: 0 0 2vh 0;
    }

    // .home__portfolio-title

    &__portfolio-title {
      margin: 0 24px 24px 24px;
    }

    // .home__portfolio-link

    &__portfolio-link {
    }

    // .home__portfolio-list

    &__portfolio-list {
      display: flex;
      align-items: stretch;
      height: initial;
      padding-left: 24px;
      max-width: initial;
    }

    // .home__portfolio-item

    &__portfolio-item {
      min-width: 300px;
      max-width: 300px;
      margin-right: 24px;
      max-height: 320px;
      min-height: 300px;
      margin-bottom: 0;
    }

    // .home__portfolio-info

    &__portfolio-info {
    }

    // .home__portfolio-name

    &__portfolio-name {
    }

    // .home__portfolio-description

    &__portfolio-description {
      display: none;
    }

    // .home__portfolio-label

    &__portfolio-label {
      margin-top: 6px;
    }
  }
  .mobile {
    // .mobile__link-portfolio

    &__link-portfolio {
    }
  }
}

// reverse tablets

// @media (max-height: 1024px) and (max-width: 768px) {
//   .home {
//     padding-top: 3vh;

//     &__container {
//       flex-direction: column;
//     }
//     &__info {
//       max-width: 80%;
//       &-title {
//         margin: 14px 0 14px 0;
//         font-size: 72px;
//       }
//       &-subtitle {
//         margin-bottom: 20px;
//       }
//     }
//     &__portfolio {
//       max-width: 100%;
//       padding-bottom: 20px;
//       padding-right: 140px;
//       height: 50%;
//       &-label {
//         display: none;
//       }
//       &-title {
//         margin-bottom: 10px;
//       }
//       &-description {
//         display: none;
//       }
//       &-list {
//         display: flex;
//       }
//       &-link {
//         padding-right: 10px;
//       }
//       &-item {
//         min-width: 300px;
//         min-height: initial;
//       }
//     }
//   }
// }

// tablets

// @media (max-height: 768px) and (max-width: 1200px) {
//   .home {
//     padding-top: 5vh;

//     &__container {
//       flex-direction: column;
//     }
//     &__info {
//       max-width: 80%;
//       &-title {
//         font-size: 72px;
//         margin: 14px 0 14px 0;
//       }
//       &-subtitle {
//         margin-bottom: 20px;
//       }
//     }
//     &__portfolio {
//       max-width: 100%;
//       padding-bottom: 20px;
//       height: 50%;
//       padding: 0;
//       padding-bottom: 20px;
//       &-label {
//         display: none;
//       }
//       &-title {
//         margin-bottom: 10px;
//       }
//       &-description {
//         display: none;
//       }
//       &-list {
//         display: flex;
//       }
//       &-link {
//         padding-right: 10px;
//       }
//       &-item {
//         min-width: 300px;
//         min-height: initial;
//       }
//     }
//   }
// }

// mobile

@media (max-width: 767px) and (min-width: 300px) {
  .home {
    &__info {
      padding: 1vh 12px 0px 12px;
      padding-bottom: 24px;
      max-width: initial;
      &-suptitle {
        font-size: 20px;
        display: flex;
        flex-direction: column;
        span {
          width: 100%;
          margin-top: 4px;
        }
        &-hire {
          // display: initial;
        }
      }
      &-title {
        font-size: 12vw;
        margin: 12px 0;
      }
      &-subtitle {
        font-size: 16px;
        margin-bottom: 24px;
      }
      &-hire {
      }
    }
    &__portfolio {
      // display: none;
      &-title {
        font-size: 20px;
        margin: 0 12px 24px 12px;
      }
      &-link {
        font-size: 16px;
      }
      &-list {
        padding-left: 12px;
      }
      &-item {
      }
    }
  }

  // .mobile__link-portfolio {
  //   display: flex;
  //   position: fixed;
  //   bottom: 0;
  //   left: 0;
  //   width: 100%;
  //   padding: 14px;
  //   background-color: $white;
  //   color: $dark;
  //   display: flex;
  //   justify-content: center;
  // }
}
@media (max-width: 414px) {
  .home {
    &__info {
      &-suptitle {
        span {
        }
      }
      &-title {
      }
      &-subtitle {
      }
      &-hire {
      }
    }
    &__portfolio {
      // display: none;
      &-title {
      }
      &-link {
        margin-left: 24px;
      }
      &-list {
      }
      &-item {
        min-width: 80vw;
      }
    }
  }

  // .mobile__link-portfolio {
  //   display: flex;
  //   position: fixed;
  //   bottom: 0;
  //   left: 0;
  //   width: 100%;
  //   padding: 14px;
  //   background-color: $white;
  //   color: $dark;
  //   display: flex;
  //   justify-content: center;
  // }
}

// @media (max-width: 768px) and (min-height: 768px) {
//   .home {
//     height: 100%;
//     &__info {
//       padding-bottom: 20px;
//     }
//     &__container {
//       height: 100%;
//       justify-content: space-between;
//     }
//     &__portfolio {
//       display: initial;
//       padding-right: 0;
//       height: auto;
//       &-title {
//         width: 100%;
//         font-size: 18px;
//       }
//       &-list {
//         align-items: flex-end;
//         height: initial;
//       }
//       &-item {
//         min-width: 250px;
//         min-height: 40vh;
//         margin-bottom: 0;
//       }
//       &-name,
//       &-link {
//         font-size: 16px;
//       }
//     }
//   }
//   .mobile__link-portfolio {
//     display: none;
//   }
// }
</style>

import { createRouter, createWebHistory } from "vue-router";
import page_transitions from "../animations";
import store from "../store/index";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/404",
    name: "404",
    // route level code-splitting
    // this generates a separate chunk (portfolio.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "404" */ "../views/404.vue"),
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    params: {},
    // route level code-splitting
    // this generates a separate chunk (portfolio.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "portfolio" */ "../views/Portfolio.vue"),
  },
  {
    path: "/contacts",
    name: "Contacts",
    // route level code-splitting
    // this generates a separate chunk (portfolio.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "contacts" */ "../views/Contacts.vue"),
  },

  {
    path: "/case/:case_name",
    name: "Case",
    component: () => import(/* webpackChunkName: "case" */ "../views/Case.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

let isFirstLoad = true;

router.beforeEach((to, from, next) => {
  console.log(store.state.off_router);
  if (store.state.off_router) {
    console.log("tha1");
    next(false);
    return;
  }
  if (isFirstLoad) {
    isFirstLoad = false;
    page_transitions.page_transition.first_open().then(() => {
      store.state.first_load_done = true;
    });
    next();
  } else {
    let disable_router = store.getters?.ROUTER_STATE?.disable_router;
    if (disable_router) next();
    else {
      page_transitions.page_transition.second_open().then((timeline) => {
        next();
        page_transitions.page_transition.second_close(timeline);
      });
    }
  }
});

// router.beforeResolve((to, from, next) => {

//   next();
// });

export default router;

<template>
  <div class="case-loader">
    <div class="case-loader__animate">
      <div class="case-loader__animate-item--helper">
        <div class="case-loader__animate-item"></div>
      </div>
      <div class="case-loader__animate-item--helper">
        <div class="case-loader__animate-item"></div>
      </div>
      <div class="case-loader__animate-item--helper">
        <div class="case-loader__animate-item"></div>
      </div>
    </div>
    <div class="case-loader__wrapper">
      <h2 class="case-loader__title">{{ case_data.name }}</h2>
      <p class="case-loader__tasks">{{ case_data?.short_tasks || "" }}</p>
      <p class="case-loader__delay">{{ case_data?.case_close_delay }}</p>
    </div>
    <div class="case-loader__firstLoad-wrapper">
      <h2 class="case-loader__firstLoad-title">
        <div class="case-loader__firstLoad-txtWrapper">
          <span
            class="case-loader__firstLoad-letterWrapper"
            ref="animate_letter"
            ><span class="case-loader__firstLoad-letter">Р</span></span
          >
          <span
            class="case-loader__firstLoad-letterWrapper"
            ref="animate_letter"
            ><span class="case-loader__firstLoad-letter">а</span></span
          >
          <span
            class="case-loader__firstLoad-letterWrapper"
            ref="animate_letter"
            ><span class="case-loader__firstLoad-letter">з</span></span
          >
          <span
            class="case-loader__firstLoad-letterWrapper"
            ref="animate_letter"
            ><span class="case-loader__firstLoad-letter">р</span></span
          >
          <span
            class="case-loader__firstLoad-letterWrapper"
            ref="animate_letter"
            ><span class="case-loader__firstLoad-letter">а</span></span
          >
          <span
            class="case-loader__firstLoad-letterWrapper"
            ref="animate_letter"
            ><span class="case-loader__firstLoad-letter">б</span></span
          >
          <span
            class="case-loader__firstLoad-letterWrapper"
            ref="animate_letter"
            ><span class="case-loader__firstLoad-letter">о</span></span
          >
          <span
            class="case-loader__firstLoad-letterWrapper"
            ref="animate_letter"
            ><span class="case-loader__firstLoad-letter">т</span></span
          >
          <span
            class="case-loader__firstLoad-letterWrapper"
            ref="animate_letter"
            ><span class="case-loader__firstLoad-letter">к</span></span
          >
          <span
            class="case-loader__firstLoad-letterWrapper letter-margins-right"
            ref="animate_letter"
          >
            <span class="case-loader__firstLoad-letter">а</span>
          </span>
          <span
            class="case-loader__firstLoad-letterWrapper"
            ref="animate_letter"
            ><span class="case-loader__firstLoad-letter">U</span></span
          >
          <span
            class="case-loader__firstLoad-letterWrapper"
            ref="animate_letter"
            ><span class="case-loader__firstLoad-letter">I</span></span
          >
          <span
            class="case-loader__firstLoad-letterWrapper letter-margins"
            ref="animate_letter"
          >
            <span class="case-loader__firstLoad-letter">|</span></span
          >
          <span
            class="case-loader__firstLoad-letterWrapper"
            ref="animate_letter"
            ><span class="case-loader__firstLoad-letter">U</span></span
          >
          <span
            class="case-loader__firstLoad-letterWrapper"
            ref="animate_letter"
            ><span class="case-loader__firstLoad-letter">X</span></span
          >
        </div>

        <div class="case-loader__firstLoad-txtWrapper">
          <span
            class="case-loader__firstLoad-letterWrapper letter-margins-right"
            ref="animate_letter"
          >
            <span class="case-loader__firstLoad-letter">&</span>
          </span>
          <span
            class="case-loader__firstLoad-letterWrapper"
            ref="animate_letter"
            ><span class="case-loader__firstLoad-letter">e</span></span
          >
          <span
            class="case-loader__firstLoad-letterWrapper"
            ref="animate_letter"
            ><span class="case-loader__firstLoad-letter">-</span></span
          >
          <span
            class="case-loader__firstLoad-letterWrapper"
            ref="animate_letter"
            ><span class="case-loader__firstLoad-letter">c</span></span
          >
          <span
            class="case-loader__firstLoad-letterWrapper"
            ref="animate_letter"
            ><span class="case-loader__firstLoad-letter">o</span></span
          >
          <span
            class="case-loader__firstLoad-letterWrapper"
            ref="animate_letter"
            ><span class="case-loader__firstLoad-letter">m</span></span
          >
          <span
            class="case-loader__firstLoad-letterWrapper"
            ref="animate_letter"
            ><span class="case-loader__firstLoad-letter">m</span></span
          >
          <span
            class="case-loader__firstLoad-letterWrapper"
            ref="animate_letter"
            ><span class="case-loader__firstLoad-letter">e</span></span
          >
          <span
            class="case-loader__firstLoad-letterWrapper"
            ref="animate_letter"
            ><span class="case-loader__firstLoad-letter">r</span></span
          >
          <span
            class="case-loader__firstLoad-letterWrapper"
            ref="animate_letter"
            ><span class="case-loader__firstLoad-letter">c</span></span
          >
          <span
            class="case-loader__firstLoad-letterWrapper"
            ref="animate_letter"
            ><span class="case-loader__firstLoad-letter">e</span></span
          >
        </div>
      </h2>
    </div>
  </div>
</template>
<script>
export default {
  name: "v-case-loader",
  data() {
    return {};
  },
  props: {
    case_data: {
      Type: Object,
      default() {},
    },
  },
  methods: {},
};
</script>
<style lang="scss">
.case-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -2;
  opacity: 0;
  &__wrapper {
    padding: 2vw;
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: -1;
  }
  &__animate {
    display: flex;
    height: 100%;
    &-item--helper {
      max-width: 100%;
      width: 100%;
      overflow: hidden;
      &:nth-child(1) div {
        background-color: #202020;
      }
      &:nth-child(2) div {
        background-color: #333333;
      }
      &:nth-child(3) div {
        background-color: #4d4d4d;
      }
      div {
        height: 100%;
      }
    }
  }
  &__title {
    @include h2;
    color: $white;
    height: 0px;
    position: relative;
    overflow: hidden;
  }
  &__tasks {
    color: $white;
    height: 0px;
    overflow: hidden;
  }
  &__delay {
    min-width: 48px;
    min-height: 48px;
    max-width: 48px;
    max-height: 48px;
    margin-top: 12px;
    opacity: 0;
    border-radius: 50%;
    background-color: $white;
    color: $dark;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__firstLoad {
    &-wrapper {
      position: absolute;
      bottom: 100px;
      left: 50%;
      transform: translateX(-50%);
      max-width: 90vw;
      width: 100%;
      color: $white;
    }
    &-title {
      display: flex;
      flex-direction: column;
      color: inherit;
      font-size: 96px;
      // z-index: -1;
    }
    &-txtWrapper {
      display: flex;
    }
    &-letterWrapper {
      overflow: hidden;
      span {
        display: block;
        position: relative;
      }
    }
  }
}

.letter-margins {
  margin: 0 24px;
}
.letter-margins-right {
  margin-right: 24px;
}

@media (max-width: 1440px) {
}
@media (max-width: 1024px) {
  .case-loader {
    // .case-loader__animate

    &__animate {
    }

    // .case-loader__animate-item--helper

    &__animate-item--helper {
    }

    // .case-loader__animate-item

    &__animate-item {
    }

    // .case-loader__wrapper

    &__wrapper {
    }

    // .case-loader__title

    &__title {
    }

    // .case-loader__tasks

    &__tasks {
    }

    // .case-loader__firstLoad-wrapper

    &__firstLoad-wrapper {
      font-weight: 400;
    }

    // .case-loader__firstLoad-title

    &__firstLoad-title {
      font-family: "Cinzel", serif;
      font-size: 9vw;
      letter-spacing: 4px;
      // letter-spacing: ;
    }

    // .case-loader__firstLoad-txtWrapper

    &__firstLoad-txtWrapper {
      font-family: "Cinzel", serif;
    }

    // .case-loader__firstLoad-letterWrapper

    &__firstLoad-letterWrapper {
      font-family: "Cinzel", serif;
    }

    // .case-loader__firstLoad-letter

    &__firstLoad-letter {
      font-family: "Cinzel", serif;
      font-weight: 500;
      font-family: inherit;
    }
  }
}
@media (max-width: 768px) {
  .case-loader {
    // .case-loader__animate

    &__animate {
    }

    // .case-loader__animate-item--helper

    &__animate-item--helper {
    }

    // .case-loader__animate-item

    &__animate-item {
    }

    // .case-loader__wrapper

    &__wrapper {
    }

    // .case-loader__title

    &__title {
    }

    // .case-loader__tasks

    &__tasks {
    }

    // .case-loader__firstLoad-wrapper

    &__firstLoad-wrapper {
    }

    // .case-loader__firstLoad-title

    &__firstLoad-title {
    }

    // .case-loader__firstLoad-txtWrapper

    &__firstLoad-txtWrapper {
    }

    // .case-loader__firstLoad-letterWrapper

    &__firstLoad-letterWrapper {
    }

    // .case-loader__firstLoad-letter

    &__firstLoad-letter {
    }
  }
}

@media (max-width: 767px) {
  .case-loader {
    &__animate {
      flex-direction: column;
      &-item--helper {
        height: 100%;
      }
    }
  }
}

@media (max-width: 640px) {
  .letter-margins {
    margin: 0 4px;
  }
  .letter-margins-right {
    margin-right: 16px;
  }
  .case-loader {
    &__wrapper {
      left: 20px;
      transform: translate(0);
    }
    &__title {
      font-size: 14vw;
    }
    &__firstLoad {
      &-title {
        // font-size: 9vw;
      }
    }
  }
}
</style>

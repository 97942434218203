import { createStore } from "vuex";
import cases from "../data/cases.json";
export default createStore({
  state: {
    portfolio: cases,
    case: {},
    disable_router: false,
    off_router: false,
    first_load_done: false,
    home_page_load_done: false,
    window_width: undefined,
  },
  mutations: {
    SET_CASE_DATA(state, data) {
      state.case = data;
    },
    SET_ROUTER_DISABLE_STATE(state) {
      state.disable_router = !state.disable_router;
    },
  },
  actions: {
    GET_CASE_DATA({ commit, state }, case_name) {
      console.log(case_name);
      let case_data =
        state.portfolio?.filter(
          (case_data) => case_data?.name === case_name
        )?.[0] || null;
      console.log(case_data);
      if (case_data !== null) {
        commit("SET_CASE_DATA", case_data);
        return true;
      } else return false;
    },
    SET_ROUTER_DISABLE({ commit }) {
      commit("SET_ROUTER_DISABLE_STATE");
    },
  },
  getters: {
    PORTFOLIO(state) {
      return state.portfolio;
    },
    CASE_DATA(state) {
      return state.case;
    },
    ROUTER_STATE(state) {
      return { disable_router: state.disable_router };
    },
    FIRST_LOAD_DONE(state) {
      return state.first_load_done;
    },
  },
  modules: {},
});
